import React, { useState } from 'react';
import { Collapse, Button, Table } from 'reactstrap';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import log from 'loglevel';

function Metrics ( props )
{
    return <div className="cursiveCostPanelMetrics">
        {props.metrics.numberAcross} across, {props.metrics.numberAround.toFixed(3)} around; {props.metrics.labelsPerFt.toFixed(3)} / ft ({props.metrics.msiPerMImp.toFixed(3)} sq in / MImp)
    </div>
}

function CostLineItemTable ( props )
{
    var total = 0;

    const lineItems = props.lineItems.map ( (lineItem,index) => { total += lineItem.cost; return <tr key={lineItem.op + ":" + lineItem.category + ":" + lineItem.description + ":" + index} >
        <td>{lineItem.op}</td>
        <td>{lineItem.system}</td>
        <td>{lineItem.category}</td>
        <td className="cursiveRightAlignCell">{lineItem.qty}</td>
        <td>{lineItem.description}</td>
        <td className="cursiveRightAlignCell">{lineItem.unitCost}</td>
        <td className="cursiveRightAlignCell">{lineItem.cost}</td>
    </tr>} );

    return <Table>
        <thead>
            <tr>
                <th>Operation</th>
                <th>System</th>
                <th>Category</th>
                <th>Qty</th>
                <th>Description</th>
                <th>Unit Cost</th>
                <th>Line Cost</th>
            </tr>
        </thead>
        <tbody>
            {lineItems}
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <th className="cursiveRightAlignCell">{total.toFixed(3)}</th>
            </tr>
        </tbody>
    </Table>
}

function CostPathMetricsTable ( props )
{
    const explainers = props.explainers && props.explainers.lengthCalcs ? props.explainers.lengthCalcs : [];

    const lineItems = explainers.map ( (textItem,index) => { return <p className="cursiveExplainerText">{textItem}</p>; } );
    return <div className="cursiveExplainerBox">
        <p className="cursiveExplainerText"><strong>Run Lengths</strong></p>
        {lineItems}
    </div>;
}

function QtyBreak ( props )
{
    const [showCostDetails, setShowCostDetails] = useState(false);

    function toggle ()
    {
        setShowCostDetails ( !showCostDetails );
    };

    return <>
        <div><Button outline size="sm" onClick={toggle} style={{ marginBottom: '1rem' }}>{showCostDetails?<EyeSlash/>:<Eye/>}</Button> {props.qbCostSection.qty} MM: $ {props.qbCostSection.costStr} / MM</div>
        <Collapse isOpen={showCostDetails} >
            <CostPathMetricsTable explainers={props.qbCostSection.explain} />
            <CostLineItemTable lineItems={props.qbCostSection.lineItems} />
        </Collapse>
    </>;
}

export function CostsForRoute ( props )
{
    return <div className="cursiveCostPanel">
        <h4>{props.route.displayName}</h4>
        <Metrics metrics={props.route.metrics} />
        {
            props.route.costs.map ( (qbCostSection) => { return <QtyBreak key={qbCostSection.qtyBreakId} qbCostSection={qbCostSection} /> } )
        }
    </div> 
}
